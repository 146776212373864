.services-container {
  border-bottom: 5px dotted #87cdec;
  border-bottom-style: double;

  .services-header-container {
    text-align: center;

    .services-title {
      font-size: var(--title-font-size-mobile);
    }

    .services-subtitle {
      font-size: var(--subtitle-font-size-mobile);
    }
  }

  .service-collection {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    gap: 30px;
  }
}

@media (min-width: 800px) {
  .services-container {
    .services-header-container {
      .services-title {
        font-size: var(--title-font-size);
      }

      .services-subtitle {
        font-size: var(--subtitle-font-size);
      }
    }

    .service-collection {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (min-width: 1300px) {
  .services-container {
    .services-header-container {
      .services-title {
        font-size: var(--title-font-size);
      }

      .services-subtitle {
        font-size: var(--subtitle-font-size);
      }
    }

    .service-collection {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
